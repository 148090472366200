export interface Business {
  id: string;
  masterUserId?: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressPostCode: string;
  businessId: string;
  createdDate?: Date | string;
  updatedDate?: Date | string;
}

export default class BusinessDetails implements Business {
  id = '';

  masterUserId = '';

  name = '';

  addressLine1 = '';

  addressLine2 = '';

  addressCity = '';

  addressPostCode = '';

  businessId = '';

  createdDate = '';

  updatedDate = '';
}

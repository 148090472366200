















































































import { Component, Vue } from 'vue-property-decorator';
import { Container } from 'inversify';
import { Account, AuthError } from 'msal';
import BusinessDetails, { Business } from '../../interfaces/api/Business';
import container from '../../injection/Container';
import { AuthService } from '../../msal/AuthService';

@Component
export default class Edit extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };

  private apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.sprkl.app' : process.env.VUE_APP_API_URL;

  private diContainer: Container = container;

  public busAuthService: AuthService;

  //   private businessDetailsBacking: BusinessDetails = new BusinessDetails();

  formValid = true;

  dataLoading = false;

  businessDetails: Business = new BusinessDetails();

  businessDbId = '';

  businessName = '';

  addressLine1 = '';

  addressLine2 = '';

  addressCity = '';

  addressPostCode = '';

  businessId = '';

  // snackbar
  private snackbar = false;

  private snackbarContent = '';

  private snackbarTimeout = 4000;

  private snackbarColor = 'success';

  constructor() {
    super();
    this.busAuthService = this.diContainer.getNamed<AuthService>('AuthService', 'business');
  }

  async mounted() {
    await this.mountEdit();
  }

  async mountEdit() {
    this.dataLoading = true;
    if (this.$store.state.accessToken === undefined) {
      // alert('No Token');
      try {
        const response = await this.busAuthService.login();
        this.$store.commit('authSuccess', this.busAuthService.getAccount());
        this.$store.commit('accessToken', response.idToken.rawIdToken);

        // console.log(`AccessToken ${JSON.stringify(response.idToken.rawIdToken)}`);
      } catch (e) {
        if (e instanceof AuthError) {
          //   console.log(e);
        } else {
          throw e;
        }
      }
      this.mountEdit();
      return;
    }

    fetch(`${this.apiUrl}/api/businesses`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$store.state.accessToken}`
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Response Status: ${response.status}\nMessage: ${response.statusText}`);
        }
        return response.json() as Promise<Business[]>;
      })
      .then(data => {
        if (data.length > 0) this.convertBusinessDetails(data[0]);

        this.dataLoading = false;
      })
      .catch(err => {
        this.dataLoading = false;
        this.snackbar = true;
        this.snackbarContent = 'There was an error loading the data please try again.';
        this.snackbarColor = 'error';
      });
  }

  get account(): Account | undefined {
    return this.$store.state.userAccount;
  }

  submitForm($event: any) {
    if (!this.formValid) {
      this.snackbar = true;
      this.snackbarContent = 'The input is invalid!';
      this.snackbarColor = 'error';
      return;
    }

    this.dataLoading = true;

    // Compose Object
    const data: Business = {
      id: this.businessDbId,
      name: this.businessName,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      addressCity: this.addressCity,
      addressPostCode: this.addressPostCode,
      businessId: this.businessId
    };

    // submit form
    fetch(`${this.apiUrl}/api/businesses`, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$store.state.accessToken}`
      }),
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 400) {
            this.snackbar = true;
            this.snackbarContent = 'There was an error saving your data please try again!';
            this.snackbarColor = 'error';
          }
          this.dataLoading = false;
        }
        this.snackbar = true;
        this.snackbarContent = 'You business updates have been saved!';
        this.snackbarColor = 'success';

        return response.json() as Promise<BusinessDetails[]>;
      })
      .then(businessData => {
        this.convertBusinessDetails(businessData[0]);

        this.dataLoading = false;
      });
  }

  businessRules = {
    name: [(val: any) => (val || '').length > 0 || 'This field is required'],
    line1: [(val: any) => (val || '').length > 0 || 'This field is required'],
    //   line2: [val => (val || '').length > 0 || 'This field is required'],
    city: [(val: any) => (val || '').length > 0 || 'This field is required'],
    postCode: [(val: any) => (val || '').length > 0 || 'This field is required']
    // id: [(val: any) => (val || '').length > 0 || 'This field is required']
  };

  convertBusinessDetails(data: Business) {
    this.businessDetails = data;
    this.businessDbId = data.id;
    this.businessName = data.name;
    this.addressLine1 = data.addressLine1;
    this.addressLine2 = data.addressLine2;
    this.addressCity = data.addressCity;
    this.addressPostCode = data.addressPostCode;
    this.businessId = data.businessId;
  }
}
